//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'SclvButton',
  props: {
    forceBgGrey: {
      type: Boolean,
      default: false
    },
    textWhite: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    disabledColor: {
      type: String,
      default: 'bg-gray-150'
    },
    text: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'button'
    },
    to: {
      type: String,
      default: undefined
    },
    small: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: ''
    },
    rounded: {
      type: String,
      default: 'rounded'
    }
  },
  methods: {
    onClick() {
      this.$emit('click')
      this.$refs.button?.focus()
    },
    onBlur() {
      this.$emit('blur')
    }
  }
}
