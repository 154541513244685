import { render, staticRenderFns } from "./PageBuilder.vue?vue&type=template&id=4402a944"
var script = {}


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_cache-loader@4.1.0_webpack@4.47.0__css-loader@5.2.7_webpack@4.47.0__ejs@3._54tixbz6jkcfk44fmedkayj5tu/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SclvIcon: require('/app/components/common/SclvIcon.vue').default,SclvAvatar: require('/app/components/common/SclvAvatar.vue').default})
