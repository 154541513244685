import {
  mapGettersHelper,
  mapMutationsHelper,
  initQueryParams,
  encodeQueryData,
  decodeQueryData
} from '@/utils/helpers.js'

export const state = () => ({
  facebookCustomAudiences: [],
  facebookAdAccounts: [],
  metaFacebookAdAccounts: {}
})

export const mutations = {
  ...mapMutationsHelper(state())
}

export const getters = {
  ...mapGettersHelper(state())
}

export const actions = {
  async retrieve({ commit }) {
    try {
      const url = `${this.$config.apiUrlV2}/business/fb/`
      const res = await this.$axios.$get(url)
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async set({ commit }, payload) {
    const { accessToken } = payload
    try {
      const url = `${this.$config.apiUrlV2}/business/fb/login/`
      const res = await this.$axios.$patch(url, {
        fb_access_token: accessToken
      })
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async syncAdAccount({ commit }) {
    try {
      const url = `${this.$config.apiUrlV2}/business/fb/adaccounts/sync`
      return await this.$axios.post(url)
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async fetchAllCustomAudienceByAccountId({ commit }, payload) {
    commit('SET_FACEBOOK_CUSTOM_AUDIENCES', [])
    const { accountId } = payload
    const url = `${this.$config.apiUrlV2}/business/fb/customaudiences/?account_id=${accountId}`
    try {
      const res = await this.$axios.$get(url)
      commit('SET_FACEBOOK_CUSTOM_AUDIENCES', res.data)
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  resetAdAccountsAndPages({ commit }) {
    commit('SET_META_FACEBOOK_AD_ACCOUNTS', {})
    commit('SET_FACEBOOK_AD_ACCOUNTS', [])
  },

  async fetchAllAdAccounts({ commit, state }, { query, isFirst = false } = {}) {
    if (isFirst) {
      commit('SET_META_FACEBOOK_AD_ACCOUNTS', {})
      commit('SET_FACEBOOK_AD_ACCOUNTS', [])
    }
    const { pageSize } = state.metaFacebookAdAccounts
    const initQuery = initQueryParams({
      page_size: pageSize,
      ...query
    })
    const queries = encodeQueryData(initQuery)
    const url = `${this.$config.apiUrlV2}/business/fb/adaccounts/?${queries}`
    try {
      const res = await this.$axios.$get(url)
      const results = [...state.facebookAdAccounts, ...res.data.results]
      commit('SET_FACEBOOK_AD_ACCOUNTS', results)
      let newMeta = {
        hasNext: res?.data?.has_next,
        lastId: res?.data?.last_id
      }
      const nextMeta = res.data.next
        ? decodeQueryData(res.data.next.split('?')?.[1])
        : null
      if (nextMeta) {
        newMeta = {
          ...newMeta,
          pageSize: nextMeta.page_size - 0
        }
      }
      commit('SET_META_FACEBOOK_AD_ACCOUNTS', newMeta)
      return { results: res.data.results }
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async remove({ commit }) {
    try {
      const url = `${this.$config.apiUrlV2}/business/fb/logout/`
      const res = await this.$axios.$patch(url)
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async getCampaignsByAdAccountId(
    { commit },
    { adAccountId, filter, queries } = {}
  ) {
    try {
      const payload = {}
      if (filter && filter.length > 0) {
        payload.filtering = filter
      }
      const queryParams = encodeQueryData(queries)
      const url = `${this.$config.apiUrlV2}/business/fb/adaccounts/${adAccountId}/campaigns/?${queryParams}`
      const res = await this.$axios.$post(url, payload)
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async getCampaignInsights(
    { commit },
    { adAccountId, objects, timeRange, preset } = {}
  ) {
    try {
      const payload = {
        objects
      }
      if (timeRange) {
        payload.time_range = timeRange
      }
      if (preset) {
        payload.preset = preset
      }
      const url = `${this.$config.apiUrlV2}/business/fb/adaccounts/${adAccountId}/campaigns/insights/`
      const res = await this.$axios.$post(url, payload)
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async updateCampaign(
    { commit },
    { status, adAccountId, campaignId } = {}
  ) {
    try {
      const payload = {}
      if (status) {
        payload.status = status
      }
      const url = `${this.$config.apiUrlV2}/business/fb/adaccounts/${adAccountId}/campaigns/${campaignId}/`
      const res = await this.$axios.$patch(url, payload)
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  }
}
