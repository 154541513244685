var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.to ? 'NuxtLink' : 'button',{ref:"button",tag:"components",staticClass:"inline-flex font-medium focus:outline-none",class:[
    _vm.disabled
      ? ((!_vm.text ? (_vm.disabledColor + " ") : ("" + (_vm.forceBgGrey ? _vm.disabledColor : ''))) + " cursor-default text-black-750")
      : _vm.color || 'text-gray-600',
    _vm.text
      ? ''
      : ("min-w-[80px] transform items-center justify-center px-4 py-[10px] text-center transition duration-100 ease-in-out " + _vm.rounded),
    {
      'bg-gray-100': !_vm.text && !_vm.color,
      'text-sm': _vm.small,
      'px-2': !_vm.text && _vm.small
    }
  ],attrs:{"to":_vm.to,"disabled":_vm.disabled,"type":!_vm.to && _vm.type},on:{"click":_vm.onClick,"blur":_vm.onBlur}},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }