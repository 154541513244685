import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _65820980 = () => interopDefault(import('../pages/activation.vue' /* webpackChunkName: "pages/activation" */))
const _61aaa02d = () => interopDefault(import('../pages/advertising/index.vue' /* webpackChunkName: "pages/advertising/index" */))
const _f6cb5ad4 = () => interopDefault(import('../pages/affiliate/index.vue' /* webpackChunkName: "pages/affiliate/index" */))
const _629398e5 = () => interopDefault(import('../pages/balance/index.vue' /* webpackChunkName: "pages/balance/index" */))
const _9bafb80c = () => interopDefault(import('../pages/bundles/index.vue' /* webpackChunkName: "pages/bundles/index" */))
const _085b0b80 = () => interopDefault(import('../pages/components.vue' /* webpackChunkName: "pages/components" */))
const _22d50b4a = () => interopDefault(import('../pages/components/datepicker.vue' /* webpackChunkName: "pages/components/datepicker" */))
const _63810da8 = () => interopDefault(import('../pages/components/fullscreen-image.vue' /* webpackChunkName: "pages/components/fullscreen-image" */))
const _3c047e23 = () => interopDefault(import('../pages/components/icon-list.vue' /* webpackChunkName: "pages/components/icon-list" */))
const _a3511622 = () => interopDefault(import('../pages/components/multi-select.vue' /* webpackChunkName: "pages/components/multi-select" */))
const _61f12d1b = () => interopDefault(import('../pages/components/select.vue' /* webpackChunkName: "pages/components/select" */))
const _2fb3a1f9 = () => interopDefault(import('../pages/components/text-field.vue' /* webpackChunkName: "pages/components/text-field" */))
const _fb757d6a = () => interopDefault(import('../pages/customer/index.vue' /* webpackChunkName: "pages/customer/index" */))
const _794effcc = () => interopDefault(import('../pages/dashboard.vue' /* webpackChunkName: "pages/dashboard" */))
const _67e355d4 = () => interopDefault(import('../pages/developers/index.vue' /* webpackChunkName: "pages/developers/index" */))
const _443a5fd7 = () => interopDefault(import('../pages/forget.vue' /* webpackChunkName: "pages/forget" */))
const _eb763b76 = () => interopDefault(import('../pages/insights.vue' /* webpackChunkName: "pages/insights" */))
const _be934036 = () => interopDefault(import('../pages/inventory/index.vue' /* webpackChunkName: "pages/inventory/index" */))
const _4a3bf2fa = () => interopDefault(import('../pages/invitation.vue' /* webpackChunkName: "pages/invitation" */))
const _3bcec114 = () => interopDefault(import('../pages/join.vue' /* webpackChunkName: "pages/join" */))
const _10ba8d22 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _3b424412 = () => interopDefault(import('../pages/lottie-list.vue' /* webpackChunkName: "pages/lottie-list" */))
const _18032135 = () => interopDefault(import('../pages/new-email.vue' /* webpackChunkName: "pages/new-email" */))
const _f985a9dc = () => interopDefault(import('../pages/new-password.vue' /* webpackChunkName: "pages/new-password" */))
const _91fa2352 = () => interopDefault(import('../pages/order/index.vue' /* webpackChunkName: "pages/order/index" */))
const _d4beb662 = () => interopDefault(import('../pages/orders.vue' /* webpackChunkName: "pages/orders" */))
const _7191bb4d = () => interopDefault(import('../pages/pages/index.vue' /* webpackChunkName: "pages/pages/index" */))
const _6a13e351 = () => interopDefault(import('../pages/products/index.vue' /* webpackChunkName: "pages/products/index" */))
const _5fba350d = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _bb1ec17a = () => interopDefault(import('../pages/reset-password.vue' /* webpackChunkName: "pages/reset-password" */))
const _f9b26d54 = () => interopDefault(import('../pages/setting.vue' /* webpackChunkName: "pages/setting" */))
const _55a13c76 = () => interopDefault(import('../pages/setting/billing/index.vue' /* webpackChunkName: "pages/setting/billing/index" */))
const _d99db3c0 = () => interopDefault(import('../pages/setting/boilerplate.vue' /* webpackChunkName: "pages/setting/boilerplate" */))
const _255cbecc = () => interopDefault(import('../pages/setting/business/index.vue' /* webpackChunkName: "pages/setting/business/index" */))
const _64180d16 = () => interopDefault(import('../pages/setting/developers/index.vue' /* webpackChunkName: "pages/setting/developers/index" */))
const _5d2f4583 = () => interopDefault(import('../pages/setting/discount-code/index.vue' /* webpackChunkName: "pages/setting/discount-code/index" */))
const _3ca8b1e0 = () => interopDefault(import('../pages/setting/domain/index.vue' /* webpackChunkName: "pages/setting/domain/index" */))
const _64c38a5e = () => interopDefault(import('../pages/setting/integration/index.vue' /* webpackChunkName: "pages/setting/integration/index" */))
const _e3ed2292 = () => interopDefault(import('../pages/setting/lang.vue' /* webpackChunkName: "pages/setting/lang" */))
const _797dbf90 = () => interopDefault(import('../pages/setting/payment/index.vue' /* webpackChunkName: "pages/setting/payment/index" */))
const _500cfa58 = () => interopDefault(import('../pages/setting/payment-method/index.vue' /* webpackChunkName: "pages/setting/payment-method/index" */))
const _0cf042bf = () => interopDefault(import('../pages/setting/plugin/index.vue' /* webpackChunkName: "pages/setting/plugin/index" */))
const _9465b11a = () => interopDefault(import('../pages/setting/profile/index.vue' /* webpackChunkName: "pages/setting/profile/index" */))
const _12503482 = () => interopDefault(import('../pages/setting/role/index.vue' /* webpackChunkName: "pages/setting/role/index" */))
const _4c486826 = () => interopDefault(import('../pages/setting/shipment/index.vue' /* webpackChunkName: "pages/setting/shipment/index" */))
const _3f8b37aa = () => interopDefault(import('../pages/setting/staff/index.vue' /* webpackChunkName: "pages/setting/staff/index" */))
const _726c6972 = () => interopDefault(import('../pages/setting/tax.vue' /* webpackChunkName: "pages/setting/tax" */))
const _017d1503 = () => interopDefault(import('../pages/setting/tracking/index.vue' /* webpackChunkName: "pages/setting/tracking/index" */))
const _a1cdff66 = () => interopDefault(import('../pages/setting/warehouse/index.vue' /* webpackChunkName: "pages/setting/warehouse/index" */))
const _5d7ea834 = () => interopDefault(import('../pages/setting/billing/checkout/index.vue' /* webpackChunkName: "pages/setting/billing/checkout/index" */))
const _4aba01c7 = () => interopDefault(import('../pages/setting/billing/history.vue' /* webpackChunkName: "pages/setting/billing/history" */))
const _e53fbcf0 = () => interopDefault(import('../pages/setting/business/change-ownership.vue' /* webpackChunkName: "pages/setting/business/change-ownership" */))
const _41265b00 = () => interopDefault(import('../pages/setting/business/edit.vue' /* webpackChunkName: "pages/setting/business/edit" */))
const _4d464479 = () => interopDefault(import('../pages/setting/profile/change-password.vue' /* webpackChunkName: "pages/setting/profile/change-password" */))
const _1442c179 = () => interopDefault(import('../pages/setting/profile/edit.vue' /* webpackChunkName: "pages/setting/profile/edit" */))
const _a16caa5e = () => interopDefault(import('../pages/setting/role/add.vue' /* webpackChunkName: "pages/setting/role/add" */))
const _5b89500e = () => interopDefault(import('../pages/setting/staff/add.vue' /* webpackChunkName: "pages/setting/staff/add" */))
const _1a4e2548 = () => interopDefault(import('../pages/setting/warehouse/add.vue' /* webpackChunkName: "pages/setting/warehouse/add" */))
const _3543b800 = () => interopDefault(import('../pages/setting/role/_roleId.vue' /* webpackChunkName: "pages/setting/role/_roleId" */))
const _2fa60234 = () => interopDefault(import('../pages/setting/staff/_staffId.vue' /* webpackChunkName: "pages/setting/staff/_staffId" */))
const _38ea5cd7 = () => interopDefault(import('../pages/setting/warehouse/_warehouseId/index.vue' /* webpackChunkName: "pages/setting/warehouse/_warehouseId/index" */))
const _009160d6 = () => interopDefault(import('../pages/setting/warehouse/_warehouseId/edit.vue' /* webpackChunkName: "pages/setting/warehouse/_warehouseId/edit" */))
const _c937aebe = () => interopDefault(import('../pages/setting/warehouse/_warehouseId/sharing.vue' /* webpackChunkName: "pages/setting/warehouse/_warehouseId/sharing" */))
const _0003a251 = () => interopDefault(import('../pages/shipping-fee.vue' /* webpackChunkName: "pages/shipping-fee" */))
const _139477ac = () => interopDefault(import('../pages/store/index.vue' /* webpackChunkName: "pages/store/index" */))
const _1c176355 = () => interopDefault(import('../pages/support.vue' /* webpackChunkName: "pages/support" */))
const _2c5f4770 = () => interopDefault(import('../pages/tutorial.vue' /* webpackChunkName: "pages/tutorial" */))
const _fce94024 = () => interopDefault(import('../pages/advertising/ads-manager/index.vue' /* webpackChunkName: "pages/advertising/ads-manager/index" */))
const _6a6dd82a = () => interopDefault(import('../pages/advertising/custom-metric/index.vue' /* webpackChunkName: "pages/advertising/custom-metric/index" */))
const _f91f08c6 = () => interopDefault(import('../pages/advertising/view/index.vue' /* webpackChunkName: "pages/advertising/view/index" */))
const _1aafd436 = () => interopDefault(import('../pages/balance/classic.vue' /* webpackChunkName: "pages/balance/classic" */))
const _128a63be = () => interopDefault(import('../pages/balance/epayment.vue' /* webpackChunkName: "pages/balance/epayment" */))
const _8e71cf6e = () => interopDefault(import('../pages/bundles/add.vue' /* webpackChunkName: "pages/bundles/add" */))
const _6a83cac8 = () => interopDefault(import('../pages/developers/third-party-logs.vue' /* webpackChunkName: "pages/developers/third-party-logs" */))
const _3bcec19e = () => interopDefault(import('../pages/developers/webhook-logs.vue' /* webpackChunkName: "pages/developers/webhook-logs" */))
const _6af952f4 = () => interopDefault(import('../pages/inventory/add.vue' /* webpackChunkName: "pages/inventory/add" */))
const _1da41e2f = () => interopDefault(import('../pages/inventory/balance.vue' /* webpackChunkName: "pages/inventory/balance" */))
const _7d247413 = () => interopDefault(import('../pages/inventory/conversion.vue' /* webpackChunkName: "pages/inventory/conversion" */))
const _8d1bd82a = () => interopDefault(import('../pages/inventory/flow.vue' /* webpackChunkName: "pages/inventory/flow" */))
const _03b6f6e3 = () => interopDefault(import('../pages/inventory/reduce.vue' /* webpackChunkName: "pages/inventory/reduce" */))
const _239ad5e6 = () => interopDefault(import('../pages/order/add.vue' /* webpackChunkName: "pages/order/add" */))
const _1678c160 = () => interopDefault(import('../pages/products/add.vue' /* webpackChunkName: "pages/products/add" */))
const _331f0479 = () => interopDefault(import('../pages/store/add.vue' /* webpackChunkName: "pages/store/add" */))
const _3676fcac = () => interopDefault(import('../pages/advertising/view/add.vue' /* webpackChunkName: "pages/advertising/view/add" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _27a16122 = () => interopDefault(import('../pages/other-setting/billing/download-invoice/_secret.vue' /* webpackChunkName: "pages/other-setting/billing/download-invoice/_secret" */))
const _09bf2036 = () => interopDefault(import('../pages/balance/download-invoice/_secret.vue' /* webpackChunkName: "pages/balance/download-invoice/_secret" */))
const _1c509582 = () => interopDefault(import('../pages/order/public/_secretSlug/index.vue' /* webpackChunkName: "pages/order/public/_secretSlug/index" */))
const _79a619c2 = () => interopDefault(import('../pages/order/public/_secretSlug/generated-invoice.vue' /* webpackChunkName: "pages/order/public/_secretSlug/generated-invoice" */))
const _246d739a = () => interopDefault(import('../pages/order/public/_secretSlug/success.vue' /* webpackChunkName: "pages/order/public/_secretSlug/success" */))
const _1e302c87 = () => interopDefault(import('../pages/bundles/_idBundle/index.vue' /* webpackChunkName: "pages/bundles/_idBundle/index" */))
const _cff71b98 = () => interopDefault(import('../pages/customer/_idCustomer/index.vue' /* webpackChunkName: "pages/customer/_idCustomer/index" */))
const _7b52cb02 = () => interopDefault(import('../pages/order/_id.vue' /* webpackChunkName: "pages/order/_id" */))
const _fdffa616 = () => interopDefault(import('../pages/pages/_id.vue' /* webpackChunkName: "pages/pages/_id" */))
const _cced712e = () => interopDefault(import('../pages/products/_idProduct/index.vue' /* webpackChunkName: "pages/products/_idProduct/index" */))
const _16dcba15 = () => interopDefault(import('../pages/store/_id/index.vue' /* webpackChunkName: "pages/store/_id/index" */))
const _7bd0e23d = () => interopDefault(import('../pages/bundles/_idBundle/partner.vue' /* webpackChunkName: "pages/bundles/_idBundle/partner" */))
const _f56266c2 = () => interopDefault(import('../pages/products/_idProduct/partner.vue' /* webpackChunkName: "pages/products/_idProduct/partner" */))
const _1662f033 = () => interopDefault(import('../pages/variants/_id/ocs.vue' /* webpackChunkName: "pages/variants/_id/ocs" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/activation",
    component: _65820980,
    name: "activation"
  }, {
    path: "/advertising",
    component: _61aaa02d,
    name: "advertising"
  }, {
    path: "/affiliate",
    component: _f6cb5ad4,
    name: "affiliate"
  }, {
    path: "/balance",
    component: _629398e5,
    name: "balance"
  }, {
    path: "/bundles",
    component: _9bafb80c,
    name: "bundles"
  }, {
    path: "/components",
    component: _085b0b80,
    name: "components",
    children: [{
      path: "datepicker",
      component: _22d50b4a,
      name: "components-datepicker"
    }, {
      path: "fullscreen-image",
      component: _63810da8,
      name: "components-fullscreen-image"
    }, {
      path: "icon-list",
      component: _3c047e23,
      name: "components-icon-list"
    }, {
      path: "multi-select",
      component: _a3511622,
      name: "components-multi-select"
    }, {
      path: "select",
      component: _61f12d1b,
      name: "components-select"
    }, {
      path: "text-field",
      component: _2fb3a1f9,
      name: "components-text-field"
    }]
  }, {
    path: "/customer",
    component: _fb757d6a,
    name: "customer"
  }, {
    path: "/dashboard",
    component: _794effcc,
    name: "dashboard"
  }, {
    path: "/developers",
    component: _67e355d4,
    name: "developers"
  }, {
    path: "/forget",
    component: _443a5fd7,
    name: "forget"
  }, {
    path: "/insights",
    component: _eb763b76,
    name: "insights"
  }, {
    path: "/inventory",
    component: _be934036,
    name: "inventory"
  }, {
    path: "/invitation",
    component: _4a3bf2fa,
    name: "invitation"
  }, {
    path: "/join",
    component: _3bcec114,
    name: "join"
  }, {
    path: "/login",
    component: _10ba8d22,
    name: "login"
  }, {
    path: "/lottie-list",
    component: _3b424412,
    name: "lottie-list"
  }, {
    path: "/new-email",
    component: _18032135,
    name: "new-email"
  }, {
    path: "/new-password",
    component: _f985a9dc,
    name: "new-password"
  }, {
    path: "/order",
    component: _91fa2352,
    name: "order"
  }, {
    path: "/orders",
    component: _d4beb662,
    name: "orders"
  }, {
    path: "/pages",
    component: _7191bb4d,
    name: "pages"
  }, {
    path: "/products",
    component: _6a13e351,
    name: "products"
  }, {
    path: "/register",
    component: _5fba350d,
    name: "register"
  }, {
    path: "/reset-password",
    component: _bb1ec17a,
    name: "reset-password"
  }, {
    path: "/setting",
    component: _f9b26d54,
    name: "setting",
    children: [{
      path: "billing",
      component: _55a13c76,
      name: "setting-billing"
    }, {
      path: "boilerplate",
      component: _d99db3c0,
      name: "setting-boilerplate"
    }, {
      path: "business",
      component: _255cbecc,
      name: "setting-business"
    }, {
      path: "developers",
      component: _64180d16,
      name: "setting-developers"
    }, {
      path: "discount-code",
      component: _5d2f4583,
      name: "setting-discount-code"
    }, {
      path: "domain",
      component: _3ca8b1e0,
      name: "setting-domain"
    }, {
      path: "integration",
      component: _64c38a5e,
      name: "setting-integration"
    }, {
      path: "lang",
      component: _e3ed2292,
      name: "setting-lang"
    }, {
      path: "payment",
      component: _797dbf90,
      name: "setting-payment"
    }, {
      path: "payment-method",
      component: _500cfa58,
      name: "setting-payment-method"
    }, {
      path: "plugin",
      component: _0cf042bf,
      name: "setting-plugin"
    }, {
      path: "profile",
      component: _9465b11a,
      name: "setting-profile"
    }, {
      path: "role",
      component: _12503482,
      name: "setting-role"
    }, {
      path: "shipment",
      component: _4c486826,
      name: "setting-shipment"
    }, {
      path: "staff",
      component: _3f8b37aa,
      name: "setting-staff"
    }, {
      path: "tax",
      component: _726c6972,
      name: "setting-tax"
    }, {
      path: "tracking",
      component: _017d1503,
      name: "setting-tracking"
    }, {
      path: "warehouse",
      component: _a1cdff66,
      name: "setting-warehouse"
    }, {
      path: "billing/checkout",
      component: _5d7ea834,
      name: "setting-billing-checkout"
    }, {
      path: "billing/history",
      component: _4aba01c7,
      name: "setting-billing-history"
    }, {
      path: "business/change-ownership",
      component: _e53fbcf0,
      name: "setting-business-change-ownership"
    }, {
      path: "business/edit",
      component: _41265b00,
      name: "setting-business-edit"
    }, {
      path: "profile/change-password",
      component: _4d464479,
      name: "setting-profile-change-password"
    }, {
      path: "profile/edit",
      component: _1442c179,
      name: "setting-profile-edit"
    }, {
      path: "role/add",
      component: _a16caa5e,
      name: "setting-role-add"
    }, {
      path: "staff/add",
      component: _5b89500e,
      name: "setting-staff-add"
    }, {
      path: "warehouse/add",
      component: _1a4e2548,
      name: "setting-warehouse-add"
    }, {
      path: "role/:roleId",
      component: _3543b800,
      name: "setting-role-roleId"
    }, {
      path: "staff/:staffId",
      component: _2fa60234,
      name: "setting-staff-staffId"
    }, {
      path: "warehouse/:warehouseId",
      component: _38ea5cd7,
      name: "setting-warehouse-warehouseId"
    }, {
      path: "warehouse/:warehouseId/edit",
      component: _009160d6,
      name: "setting-warehouse-warehouseId-edit"
    }, {
      path: "warehouse/:warehouseId/sharing",
      component: _c937aebe,
      name: "setting-warehouse-warehouseId-sharing"
    }]
  }, {
    path: "/shipping-fee",
    component: _0003a251,
    name: "shipping-fee"
  }, {
    path: "/store",
    component: _139477ac,
    name: "store"
  }, {
    path: "/support",
    component: _1c176355,
    name: "support"
  }, {
    path: "/tutorial",
    component: _2c5f4770,
    name: "tutorial"
  }, {
    path: "/advertising/ads-manager",
    component: _fce94024,
    name: "advertising-ads-manager"
  }, {
    path: "/advertising/custom-metric",
    component: _6a6dd82a,
    name: "advertising-custom-metric"
  }, {
    path: "/advertising/view",
    component: _f91f08c6,
    name: "advertising-view"
  }, {
    path: "/balance/classic",
    component: _1aafd436,
    name: "balance-classic"
  }, {
    path: "/balance/epayment",
    component: _128a63be,
    name: "balance-epayment"
  }, {
    path: "/bundles/add",
    component: _8e71cf6e,
    name: "bundles-add"
  }, {
    path: "/developers/third-party-logs",
    component: _6a83cac8,
    name: "developers-third-party-logs"
  }, {
    path: "/developers/webhook-logs",
    component: _3bcec19e,
    name: "developers-webhook-logs"
  }, {
    path: "/inventory/add",
    component: _6af952f4,
    name: "inventory-add"
  }, {
    path: "/inventory/balance",
    component: _1da41e2f,
    name: "inventory-balance"
  }, {
    path: "/inventory/conversion",
    component: _7d247413,
    name: "inventory-conversion"
  }, {
    path: "/inventory/flow",
    component: _8d1bd82a,
    name: "inventory-flow"
  }, {
    path: "/inventory/reduce",
    component: _03b6f6e3,
    name: "inventory-reduce"
  }, {
    path: "/order/add",
    component: _239ad5e6,
    name: "order-add"
  }, {
    path: "/products/add",
    component: _1678c160,
    name: "products-add"
  }, {
    path: "/store/add",
    component: _331f0479,
    name: "store-add"
  }, {
    path: "/advertising/view/add",
    component: _3676fcac,
    name: "advertising-view-add"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }, {
    path: "/other-setting/billing/download-invoice/:secret?",
    component: _27a16122,
    name: "other-setting-billing-download-invoice-secret"
  }, {
    path: "/balance/download-invoice/:secret?",
    component: _09bf2036,
    name: "balance-download-invoice-secret"
  }, {
    path: "/order/public/:secretSlug",
    component: _1c509582,
    name: "order-public-secretSlug"
  }, {
    path: "/order/public/:secretSlug?/generated-invoice",
    component: _79a619c2,
    name: "order-public-secretSlug-generated-invoice"
  }, {
    path: "/order/public/:secretSlug?/success",
    component: _246d739a,
    name: "order-public-secretSlug-success"
  }, {
    path: "/bundles/:idBundle",
    component: _1e302c87,
    name: "bundles-idBundle"
  }, {
    path: "/customer/:idCustomer",
    component: _cff71b98,
    name: "customer-idCustomer"
  }, {
    path: "/order/:id",
    component: _7b52cb02,
    name: "order-id"
  }, {
    path: "/pages/:id",
    component: _fdffa616,
    name: "pages-id"
  }, {
    path: "/products/:idProduct",
    component: _cced712e,
    name: "products-idProduct"
  }, {
    path: "/store/:id",
    component: _16dcba15,
    name: "store-id"
  }, {
    path: "/bundles/:idBundle/partner",
    component: _7bd0e23d,
    name: "bundles-idBundle-partner"
  }, {
    path: "/products/:idProduct/partner",
    component: _f56266c2,
    name: "products-idProduct-partner"
  }, {
    path: "/variants/:id?/ocs",
    component: _1662f033,
    name: "variants-id-ocs"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
