import cloneDeep from 'lodash/cloneDeep'
import findIndex from 'lodash/findIndex'
import reduce from 'lodash/reduce'
import {
  encodeQueryData,
  isEmpty,
  mapGettersHelper,
  mapMutationsHelper
} from '@/utils/helpers.js'

const groupInventoryBalance = (
  inventoryBalance,
  owners,
  warehouses,
  products,
  variants
) => {
  const balancePure = cloneDeep(inventoryBalance)
  const currentBalance = []
  // GROUPING
  balancePure.forEach((x) => {
    const variant = variants.find((y) => y.id === x.variant)
    const productByVariant = products.find((y) => y.id === variant.product)
    const businessByProduct = owners.find(
      (y) => y.id === productByVariant.business
    )
    const warehouse = warehouses.find((y) => y.id === x.warehouse)
    const businessByWarehouse = owners.find((y) => y.id === warehouse.business)
    currentBalance.push({
      id: x.id,
      variant: {
        id: x.variant,
        ...variant,
        product: {
          id: x.variant.product,
          ...productByVariant,
          business: businessByProduct
        }
      },
      warehouse: {
        id: x.warehouse,
        ...warehouse,
        business: businessByWarehouse
      },
      available_qty: x.available_qty,
      processed_qty: x.processed_qty,
      shipped_qty: x.shipped_qty,
      in_warehouse_qty: x.in_warehouse_qty
    })
  })
  const grouped = reduce(
    currentBalance,
    (fixedList, balItem) => {
      const warehouseExist = findIndex(fixedList, {
        id: balItem.warehouse.id
      })
      const currentWarehouse =
        warehouseExist < 0
          ? {
              id: balItem.warehouse.id,
              warehouse_name: balItem.warehouse.name,
              business: balItem.warehouse.business,
              products: []
            }
          : fixedList[warehouseExist]
      const productExist = findIndex(currentWarehouse.products, {
        id: balItem.variant.product.id
      })
      const currentProduct =
        productExist < 0
          ? {
              id: balItem.variant.product.id,
              name: balItem.variant.product.name,
              business: balItem.variant.product.business,
              variants: []
            }
          : currentWarehouse.products[productExist]
      currentProduct.variants.push({
        ...balItem.variant,
        available_qty: balItem.available_qty,
        processed_qty: balItem.processed_qty,
        shipped_qty: balItem.shipped_qty,
        in_warehouse_qty: balItem.in_warehouse_qty
      })
      if (productExist < 0) {
        currentWarehouse.products.push(currentProduct)
      } else {
        currentWarehouse.products[productExist] = currentProduct
      }
      if (warehouseExist < 0) {
        fixedList.push(currentWarehouse)
      } else {
        fixedList[warehouseExist] = currentWarehouse
      }
      return fixedList
    },
    []
  )
  // GROUPING END
  return grouped
  // SORTING
  // const sortedGroupedWarehouse = sortBy(grouped, ['warehouse_name'])
  // const sortedGroupedProduct = map(sortedGroupedWarehouse, (warehs) => {
  //   const prodVarSorted = map(warehs.products, (prod) => {
  //     if (prod.variants.length > 0) {
  //       return {
  //         ...prod,
  //         variants: sortBy(prod.variants, ['name'])
  //       }
  //     }
  //     return prod
  //   })
  //   const prodSorted =
  //     prodVarSorted.length > 0 ? sortBy(prodVarSorted, ['name']) : prodVarSorted
  //   return {
  //     ...warehs,
  //     products: prodSorted
  //   }
  // })
  // // SORTING END

  // return sortedGroupedProduct
}

export const state = () => ({
  holdGrouping: false,
  groupedInventoryBalance: [],
  loading: false,
  inventoryBalance: [],
  warehouses: [],
  owners: [],
  products: [],
  variants: []
})

export const mutations = {
  ...mapMutationsHelper(state())
}

export const getters = {
  ...mapGettersHelper(state())
}

export const actions = {
  async fetchAll({ state, commit, getters }, { query = {}, isFirst = false }) {
    try {
      commit('SET_LOADING', true)
      let queries = ''
      if (isFirst) {
        commit('SET_INVENTORY_BALANCE', [])
        commit('SET_GROUPED_INVENTORY_BALANCE', [])
      }
      if (!isEmpty(query)) {
        query = {
          business_id: query.businessId,
          product_id: query.productId,
          variant_id: query.variantId,
          warehouse_id: query.warehouseId,
          search: query.search
        }
      }
      queries = `?${encodeQueryData(query)}`
      const url = `inventory-balance/${queries}`
      const resInventoryBalance = await this.$axios.$get(url)
      commit('SET_INVENTORY_BALANCE', resInventoryBalance.data)
      commit('SET_HOLD_GROUPING', true)
      const grouped = groupInventoryBalance(
        resInventoryBalance.data,
        getters.getOwners,
        getters.getWarehouses,
        getters.getProducts,
        getters.getVariants
      )
      commit('SET_GROUPED_INVENTORY_BALANCE', grouped)
      commit('SET_HOLD_GROUPING', false)
      commit('SET_LOADING', false)
      return resInventoryBalance.data
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      commit('SET_HOLD_GROUPING', false)
      commit('SET_LOADING', false)
      throw error
    }
  },

  async fetchAllWarehouses({ commit }) {
    try {
      const url = 'inventory-balance/warehouse/'
      const resWarehouse = await this.$axios.$get(url)
      commit('SET_WAREHOUSES', resWarehouse.data)
      return resWarehouse
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      commit('SET_LOADING', false)
      throw error
    }
  },
  async fetchAllOwners({ commit }) {
    try {
      const url = 'inventory-balance/business/'
      const resOwners = await this.$axios.$get(url)
      commit('SET_OWNERS', resOwners.data)
      return resOwners
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      commit('SET_LOADING', false)
      throw error
    }
  },
  async fetchAllProducts({ commit }, query) {
    try {
      let queries = ''
      if (!isEmpty(query)) {
        const fixedQuery = {
          business_id: query.businessId,
          warehouse_id: query.warehouseId
        }
        queries = `?${encodeQueryData(fixedQuery)}`
      }
      const url = `inventory-balance/product/${queries}`
      const resProducts = await this.$axios.$get(url)
      commit('SET_PRODUCTS', resProducts.data)
      return resProducts
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      commit('SET_LOADING', false)
      throw error
    }
  },
  async fetchAllVariants({ commit }, query) {
    try {
      let queries = ''
      if (!isEmpty(query)) {
        const fixedQuery = {
          business_id: query.businessId,
          warehouse_id: query.warehouseId,
          product_id: query.productId
        }
        queries = `?${encodeQueryData(fixedQuery)}`
      }
      const url = `inventory-balance/variant/${queries}`
      const resVariants = await this.$axios.$get(url)
      commit('SET_VARIANTS', resVariants.data)
      return resVariants
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      commit('SET_LOADING', false)
      throw error
    }
  },
  setLoading({ commit }, payload) {
    commit('SET_LOADING', payload)
  }
}
