//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import map from 'lodash/map'
import clone from 'lodash/clone'

export default {
  name: 'SclvTab',
  props: {
    value: {
      type: [String, Number],
      default: 0
    },
    dataTabs: {
      type: Array,
      default: () => []
    },
    hideTab: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dataModel: this.value,
      openTab: this.value,
      openedTabBefore: this.value > 0 ? this.value - 1 : 1,
      tabMoveAction: '',
      tabContentWrapperXPosition: this.value > 0 ? -50 : 0,
      dataTabPosition: [],
      isTransitionTabContent: false,
      oldDataTab: clone(this.dataTabs)
    }
  },
  computed: {
    tabHeaders() {
      return map(this.dataTabs, (tab) => tab.title)
    },
    tabContents() {
      return map(this.dataTabs, (tab) => tab.content)
    }
  },
  watch: {
    value(val, oldVal) {
      this.changeTab(val, oldVal)
    },
    dataModel(val) {
      this.$emit('input', val)
    },
    tabMoveAction(val) {
      if (val === 'next') {
        this.tabContentWrapperXPosition = -50
      } else if (val === 'before') {
        this.tabContentWrapperXPosition = 0
      }
    }
  },
  mounted() {
    setTimeout(() => {
      this.initiateTabPos()
    }, 250)
  },
  methods: {
    initiateTabPos() {
      if (!this.hideTab) {
        const newTabPos = []
        let widthSiblingBefore = 0
        for (let i = 0; i < this.$refs.wrapperTabHeader.children.length; i++) {
          const element = this.$refs.wrapperTabHeader.children[i]
          const width = element.offsetWidth
          let position = width / 2 - 15
          if (i !== 0) {
            widthSiblingBefore +=
              this.$refs.wrapperTabHeader.children[i - 1].offsetWidth
            position += widthSiblingBefore
          }
          newTabPos.push(position)
        }
        this.dataTabPosition = newTabPos
      }
    },
    changeTab(tabNumber, tabNumberBefore) {
      if (tabNumber !== this.openTab) {
        this.openedTabBefore = tabNumberBefore
        this.openTab = tabNumber
        this.dataModel = tabNumber
        this.tabContentWrapperXPosition = tabNumber > tabNumberBefore ? 0 : -50
        this.tabMoveAction = ''
        setTimeout(() => {
          this.tabMoveAction = tabNumber > tabNumberBefore ? 'next' : 'before'
        }, 0)
      }
    },
    toggleTabs(tabNumber, tabNumberBefore) {
      if (tabNumber !== this.openTab) {
        this.isTransitionTabContent = false
        this.changeTab(tabNumber, tabNumberBefore)
        setTimeout(() => {
          this.isTransitionTabContent = true
        }, 0)
      }
    }
  }
}
