var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.dataTabs.length > 0)?_c('div',{staticClass:"flex w-full flex-wrap"},[_c('div',{staticClass:"flex h-full w-full flex-col"},[(!_vm.hideTab)?_c('div',{staticClass:"relative flex w-full border-b-2 border-t-0"},[_c('ul',{ref:"wrapperTabHeader",staticClass:"relative mt-[1px] flex flex-1 list-none flex-row flex-wrap px-[8px] text-[12px]"},[_vm._l((_vm.tabHeaders),function(header,i){return _c('li',{key:("tab-header-" + i),staticClass:"text-center"},[_c('a',{staticClass:"relative flex cursor-pointer justify-center px-[8px] py-[16px] text-[12px] leading-normal",class:[
              _vm.openTab === i
                ? "font-[700] text-black-1100  "
                : "font-[500] text-black-200"
            ],on:{"click":function($event){return _vm.toggleTabs(i, _vm.openTab)}}},[_vm._v("\n            "+_vm._s(header)+"\n          ")])])}),_vm._v(" "),_c('span',{staticClass:"absolute bottom-0 h-[5px] w-[28px] rounded-t bg-primary-gradient-100 transition-transform",style:(("transform: translateX(" + (_vm.dataTabPosition[_vm.openTab]) + "px)"))})],2),_vm._v(" "),_vm._t("append-menu")],2):_vm._e(),_vm._v(" "),_c('div',{staticClass:"relative flex h-full w-full min-w-0 flex-col overflow-hidden"},[_c('div',{staticClass:"flex-auto overflow-hidden"},[_c('div',{staticClass:"flex h-full",class:{ 'transition-transform': _vm.isTransitionTabContent },style:(_vm.dataTabs.length > 1
              ? ("width: 200%; transform: translateX(" + _vm.tabContentWrapperXPosition + "%)")
              : '')},_vm._l((_vm.tabContents),function(content,i){return _c('div',{key:("tab-content-" + i),staticClass:"relative h-full w-full flex-col overflow-y-auto",class:_vm.openTab === i || _vm.openedTabBefore === i
                ? 'inline-block'
                : 'hidden',attrs:{"id":"tabContent"}},[_vm._t(("content-" + i),function(){return [_c('p',{domProps:{"textContent":_vm._s(content)}})]},null,{ item: _vm.dataTabs[i], value: content })],2)}),0)])])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }