import { mapGettersHelper, mapMutationsHelper } from '@/utils/helpers.js'

export const state = () => ({})

export const mutations = {
  ...mapMutationsHelper(state())
}

export const getters = {
  ...mapGettersHelper(state())
}

export const actions = {
  async fetchByVariantId({ commit }, variantId) {
    try {
      const url = `${this.$config.apiUrlV2}/variant/${variantId}/course`
      const res = await this.$axios.$get(url)
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async fetchSectionByUuid({ commit }, uuid) {
    try {
      const url = `${this.$config.apiUrlV2}/course-section/${uuid}`
      const res = await this.$axios.$get(url)
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async fetchTopicByUuid({ commit }, uuid) {
    try {
      const url = `${this.$config.apiUrlV2}/course-topic/${uuid}`
      const res = await this.$axios.$get(url)
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async fetchContentByUuid({ commit }, uuid) {
    try {
      const url = `${this.$config.apiUrlV2}/course-content/${uuid}`
      const res = await this.$axios.$get(url)
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async updateContent({ commit }, payload) {
    const { uuid, title, text, videoUrl, isShown } = payload
    try {
      const url = `${this.$config.apiUrlV2}/course-content/${uuid}`
      const res = await this.$axios.$patch(url, {
        title,
        text,
        video_url: videoUrl,
        is_shown: isShown
      })
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async updateSection({ commit }, payload) {
    const { uuid, title, text, isShown } = payload
    try {
      const url = `${this.$config.apiUrlV2}/course-section/${uuid}`
      const res = await this.$axios.$patch(url, {
        title,
        text,
        is_shown: isShown
      })
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async updateTopic({ commit }, payload) {
    const { uuid, title, text, isShown } = payload
    try {
      const url = `${this.$config.apiUrlV2}/course-topic/${uuid}`
      const res = await this.$axios.$patch(url, {
        title,
        text,
        is_shown: isShown
      })
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async createSection({ commit }, payload) {
    const { variantId, title } = payload
    try {
      const url = `${this.$config.apiUrlV2}/variant/${variantId}/course-section`
      const res = await this.$axios.$post(url, {
        title
      })
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async createTopic({ commit }, payload) {
    const { sectionUuid, title } = payload
    try {
      const url = `${this.$config.apiUrlV2}/course-section/${sectionUuid}/course-topic`
      const res = await this.$axios.$post(url, {
        title
      })
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async createContent({ commit }, payload) {
    const { topicUuid, title } = payload
    try {
      const url = `${this.$config.apiUrlV2}/course-topic/${topicUuid}/course-content`
      const res = await this.$axios.$post(url, {
        title
      })
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async reorderContent({ commit }, payload) {
    const { topicUuid, uuids } = payload
    try {
      const url = `${this.$config.apiUrlV2}/course-topic/${topicUuid}/course-content-orders`
      const res = await this.$axios.$patch(url, {
        course_content_uuids: uuids
      })
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async reorderTopic({ commit }, payload) {
    const { sectionUuid, uuids } = payload
    try {
      const url = `${this.$config.apiUrlV2}/course-section/${sectionUuid}/course-topic-orders`
      const res = await this.$axios.$patch(url, {
        course_topic_uuids: uuids
      })
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async reorderSection({ commit }, payload) {
    const { variantId, uuids } = payload
    try {
      const url = `${this.$config.apiUrlV2}/variant/${variantId}/course-section-orders`
      const res = await this.$axios.$patch(url, {
        course_section_uuids: uuids
      })
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async deleteSection({ commit }, uuid) {
    try {
      const url = `${this.$config.apiUrlV2}/course-section/${uuid}`
      const res = await this.$axios.$delete(url)
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async deleteTopic({ commit }, uuid) {
    try {
      const url = `${this.$config.apiUrlV2}/course-topic/${uuid}`
      const res = await this.$axios.$delete(url)
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async deleteContent({ commit }, uuid) {
    try {
      const url = `${this.$config.apiUrlV2}/course-content/${uuid}`
      const res = await this.$axios.$delete(url)
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  }
}
