//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import map from 'lodash/map'
import isEqual from 'lodash/isEqual'
import {
  LOADING_TEXT_DATA_ITERATOR,
  NO_DATA_TEXT_DATA_ITERATOR
} from '@/utils/constants.js'

export default {
  props: {
    isTableHeaderShow: {
      type: Boolean,
      default: false
    },
    isTableHeaderShowMd: {
      type: Boolean,
      default: true
    },
    loadingMt: {
      type: String,
      default: 'mt-[25vh]'
    },
    fixedLayout: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    rowHeight: {
      type: Number,
      default: 0
    },
    sizeMobileCard: {
      type: Number,
      default: 16
    },
    loadingText: {
      type: String,
      default: LOADING_TEXT_DATA_ITERATOR
    },
    noDataText: {
      type: String,
      default: NO_DATA_TEXT_DATA_ITERATOR
    },
    headers: {
      type: Array,
      default: () => []
    },
    items: {
      type: Array,
      default() {
        return []
      }
    },
    showSelect: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
      default: () => ({
        page: 1,
        itemsLength: 0,
        perPage: 0
      })
    },
    rowPy: {
      type: String,
      default: 'py-[12px] md:py-[20px]'
    },
    hideLastColMobile: {
      type: Boolean,
      default: false
    },
    showAllColumns: {
      type: Boolean,
      default: false
    },
    isRounded: {
      type: Boolean,
      default: true
    },
    isBSpacing: {
      type: Boolean,
      default: true
    },
    isRowDivider: {
      type: Boolean,
      default: false
    },
    scrollX: {
      type: Boolean,
      default: false
    },
    cursorPointerRow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      internalGroupBy: [],
      fixedOptions: { ...this.options }
    }
  },
  computed: {
    freezedColWidth() {
      return this.headers.reduce((sumWidth, header) => {
        if (header.freeze && header.width) {
          sumWidth += header.width
        }
        return sumWidth
      }, 0)
    },
    wrapperHeight() {
      return `h-[${this.fixedOptions.perPage * this.rowHeight + 48}px]`
    },
    itemsBody() {
      return this.items.map((item, i) => {
        const newObj = item
        this.headers.map((header) => {
          return (newObj[header.value] = map(this.items, header.value)?.[i])
        })
        return newObj
      })
    },
    computedHeaders() {
      if (!this.headers) return []
      const headers = this.headers.filter(
        (h) =>
          h.value === undefined ||
          !this.internalGroupBy.find((v) => v === h.value)
      )
      const defaultHeader = { text: '', sortable: false, width: '2px' }
      if (this.showSelect) {
        const index = headers.findIndex((h) => h.value === 'data-table-select')
        if (index < 0)
          headers.unshift({ ...defaultHeader, value: 'data-table-select' })
        else headers.splice(index, 1, { ...defaultHeader, ...headers[index] })
      }
      return headers
    }
  },
  watch: {
    options: {
      handler(val, oldVal) {
        if (!isEqual(val, oldVal)) {
          this.fixedOptions = { ...val }
        }
      },
      deep: true
    },
    fixedOptions: {
      handler(val) {
        if (!isEqual(val, this.options)) {
          this.$emit('update:options', { ...val })
        }
      },
      deep: true
    }
  },
  methods: {
    onTrClick(item, e) {
      this.$emit('tr-click', item, e)
    }
  }
}
