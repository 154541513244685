import flatMapDeep from 'lodash/flatMapDeep'
import isObject from 'lodash/isObject'
import isEmpty from 'lodash/isEmpty'
import values from 'lodash/values'
import { CDN_URL, DEFAULT_ERROR_MESSAGE, WA_API_URL } from '@/utils/constants.js'

const capitalizeString = (str) => {
  if (str.length === 0) return str
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}

const capitalizeWords = (str) => {
  return str
    .split(' ')
    .map((word) => capitalizeString(word))
    .join(' ')
}

const errorMapper = (error = []) => {
  const flattenedError = flatMapDeep(error)
  if (isObject(flattenedError[0])) {
    return flattenedError.reduce((fixedArr, msg) => {
      if (!isEmpty(msg)) {
        fixedArr = [...fixedArr, ...values(msg)]
      }
      return flatMapDeep(fixedArr)
    }, [])
  }
  return flattenedError
}
const errorMessageHandler = (error) => {
  let message = ''
  if (error.response) {
    if (error.response.data?.errors) {
      const firstKey = Object.keys(error.response.data.errors)[0]
      const firstKeyMessage = error.response.data.errors[firstKey][0]
      message = `${capitalizeWords(
        firstKey.split('_').join(' ')
      )}: ${capitalizeString(firstKeyMessage)}${
        firstKeyMessage.slice(-1) !== '.' ? '.' : ''
      }`
    } else {
      message =
        error.response?.data?.message && isObject(error.response.data.message)
          ? errorMapper(error.response.data.message).join('\n')
          : error.response?.data?.message
    }
    return message || DEFAULT_ERROR_MESSAGE
  } else {
    return error.message || DEFAULT_ERROR_MESSAGE
  }
}
export default (ctx, inject) => {
  const { store } = ctx
  if (!ctx.$cookies.get('bhs')) {
    ctx.$cookies.set('bhs', ctx.$cookies.get('i18n_redirected') || 'id')
  }
  // Show Toast
  inject('toast', {
    showMessage({ message, color, offset, callback }) {
      store.commit('common/toast/showMessage', {
        message,
        color,
        offset,
        toastId: new Date().getTime(),
        callback
      })
    }
  })

  // Error handler, support only error format
  inject('errorHandler', (error, toastCfg) => {
    let errArr = [error]
    if (error.response) {
      const messageArr =
        typeof error.response?.data?.message === 'object'
          ? flatMapDeep(error.response?.data?.message)
          : [error.response?.data?.message]
      errArr = messageArr || [DEFAULT_ERROR_MESSAGE]
    }
    for (let i = 0; i < errArr.length; i++) {
      if (errArr[i] !== '__all__') {
        ctx.$toast.showMessage({
          message: errorMessageHandler(new Error(errArr[i])),
          color: 'danger',
          ...toastCfg
        })
      }
    }
  })

  inject('asyncEmit', async function (method, ...params) {
    const listener =
      this.$listeners[method] || this.$attrs[method] || this[method]
    if (listener) {
      // one can additionally wrap this in try/catch if needed and handle the error further
      const res = await listener(...params)
      return res === undefined || res
    }
    return false
  })

  // Error handler, support only error format
  inject('errorHandlerMessage', errorMessageHandler)

  inject('switchLang', function (locale) {
    if (locale === ctx.$cookies.get('bhs')) {
      return
    }
    // ctx.i18n.setLocale(locale)
    ctx.$cookies.set('bhs', locale)
  })

  inject('cdnUrl', CDN_URL)

  inject('apiWaUrl', function (phone, message) {
    return `${WA_API_URL}/send?phone=${phone}&text=${message}`
  })
}
