import {
  mapMutationsHelper,
  mapGettersHelper,
  encodeQueryData,
  initQueryParamsCursor
} from '@/utils/helpers.js'

export const state = () => ({
  thirdPartyLogs: [],
  metaThirdPartyLog: {
    pageSize: 25,
    next: undefined,
    isPaginationDone: false
  }
})

export const mutations = {
  ...mapMutationsHelper(state())
}

export const getters = {
  ...mapGettersHelper(state())
}

export const actions = {
  async fetchAll({ commit, state }, { query, isFirst = false } = {}) {
    if (isFirst) {
      commit('SET_THIRD_PARTY_LOGS', [])
      commit('SET_META_THIRD_PARTY_LOG', {
        pageSize: 25,
        next: undefined,
        isPaginationDone: false
      })
    }
    const { pageSize, next } = state.metaThirdPartyLog
    const initQuery = initQueryParamsCursor({
      next: isFirst ? undefined : next,
      page_size: isFirst ? 25 : pageSize,
      ...query
    })
    const queries = encodeQueryData(initQuery)
    const url = `${this.$config.apiUrlV2}/business/request-response-logs/?${queries}`
    try {
      const { data } = await this.$axios.$get(url)
      const allLogs = [...state.thirdPartyLogs, ...data.results]
      commit('SET_THIRD_PARTY_LOGS', allLogs)
      const nextMeta = {
        pageSize,
        next: data.next,
        isPaginationDone: !data.next
      }
      commit('SET_META_THIRD_PARTY_LOG', nextMeta)
      return data
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async fetchThirdPartyLog({ commit }, uniqueId) {
    try {
      const url = `${this.$config.apiUrlV2}/business/request-response-logs/${uniqueId}`
      const res = await this.$axios.$get(url)
      return res.data
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  }
}
